<template>
  <div>
    <b-form-group
      :label="label"
      :state="state"
    >
      <template
        v-if="label"
        v-slot:label
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >
          (*)
        </span>
      </template>
      <v-select
        id="doctor"
        v-model="doctor"
        multiple
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="doctors"
        placeholder="Chọn gói khám"
        :disabled="!enable"
        @input="doctorChanged"
        @open="onOpen"
      >

        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          Không có dữ liệu
        </template></v-select>
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, VBModal } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';

export default {
  components: {
    BFormGroup,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: [Array],
      default: null,
    },
    enableAdd: {
      type: Boolean,
      default: false,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    enableDefaultSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      doctor: this.defaultValue,
      doctors: [],
    };
  },
  created() {
    this.fetchDoctors();
  },
  methods: {
    doctorChanged() {
      this.$emit('input', this.doctor);
    },
    onOpen() {
      if (this.doctors.length === 0) {
        this.fetchDoctors();
      }
    },
    fetchDoctors() {
      const filter = { };
      useJwt
        .getSuggestPricing({ filter })
        .then(res => {
          this.doctors = res.data.data;
          if (!this.doctor && res.data.data && res.data.data.length > 0 && this.enableDefaultSelect) {
            // eslint-disable-next-line prefer-destructuring
            this.onItemCreated(res.data[0]);
          }
        })
        .catch(() => {
          this.doctors = [];
        });
    },
    onItemCreated(newItem) {
      this.doctor = newItem;
      this.doctorChanged();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
.list-header {
    padding: $options-padding-y $options-padding-x;
      color: $secondary;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
