import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVouchers(ctx, data) {
      return useJwt.getVouchers(data).then(response => response);
    },


    createVoucher(ctx, data) {
      return useJwt.createVoucher(data).then(response => response);
    },
    updateVoucher(ctx, data) {
      return useJwt.updateVoucher(data).then(response => response);
    },



  },
};
