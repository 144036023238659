<template>
  <b-modal
    id="modal-voucher"
    ref="refModal"
    title="Voucher"
    ok-title="Lưu"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    scrollable
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Tên voucher <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="code"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Mã voucher <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="code"
                  v-model="itemLocal.code"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <b-form-group label="Loại voucher">
              <div class="demo-inline-spacing">

                <b-form-radio
                  v-model="itemLocal.type"
                  name="type"
                  value="discount"
                  class="custom-control-primary"
                >
                  Giảm giá
                </b-form-radio>

                <b-form-radio
                  v-model="itemLocal.type"
                  name="type"
                  value="free_transaction"
                  class="custom-control-primary"
                >
                  Tặng lượt khám
                </b-form-radio>
              </div>
            </b-form-group>

          </b-col>
          <b-col
            v-if="itemLocal.type === 'discount'"
            cols="12"
            class="mb-2"
          >
            <b-form-group label="Loại giảm giá">
              <div class="demo-inline-spacing">

                <b-form-radio
                  v-model="itemLocal.discount_type"
                  name="discount_type"
                  value="percent"
                  class="custom-control-primary"
                >
                  Giảm phần trăm
                </b-form-radio>

                <b-form-radio
                  v-model="itemLocal.discount_type"
                  name="discount_type"
                  value="money"
                  class="custom-control-primary"
                >
                  Giảm số tiền
                </b-form-radio>
              </div>
            </b-form-group>

          </b-col>

          <b-col
            v-if="itemLocal.discount_type === 'percent' && itemLocal.type === 'discount'"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Phần trăm giảm giá"
              rules="required"
            >
              <number-input
                v-model="itemLocal.discount_percent"
                required
                label="Phần trăm giảm giá"
                :default-value="itemLocal.discount_percent"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col
            v-if="itemLocal.type === 'discount'"
            :md="itemLocal.discount_type === 'percent' ? 4 : 6"
          >
            <validation-provider
              v-if="itemLocal.discount_type === 'percent'"
              #default="validationContext"
              name="Số tiền giảm tối đa"
              rules="required"
            >
              <number-input
                v-model="itemLocal.max_discount"
                required
                label="Số tiền giảm tối đa"
                :default-value="itemLocal.max_discount"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
            <validation-provider
              v-else
              #default="validationContext"
              name="Số tiền giảm"
              rules="required"
            >
              <number-input
                v-model="itemLocal.discount_money"
                required
                label="Số tiền giảm"
                :default-value="itemLocal.discount_money"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col
            v-if="itemLocal.type === 'discount'"
            :md="itemLocal.discount_type === 'percent' ? 4 : 6"
          >
            <validation-provider
              #default="validationContext"
              name="Số tiền tối thiểu được áp dụng"
              rules="required"
            >
              <number-input
                v-model="itemLocal.min_money_apply"
                required
                label="Số tiền tối thiểu được áp dụng"
                :default-value="itemLocal.min_money_apply"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col
            v-if="itemLocal.type !== 'discount'"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Số lượt khám miễn phí"
              rules="required"
            >
              <number-input
                v-model="itemLocal.transaction_times"
                required
                label="Số lượt khám miễn phí"
                :default-value="itemLocal.transaction_times"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col
            v-if="itemLocal.type !== 'discount'"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Thời gian hết hạn"
              rules="required"
            >
              <select-date
                v-model="itemLocal.transaction_expire"
                required
                :default-value="itemLocal.transaction_expire"
                label="Thời gian hết hạn"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="12">
            <validation-provider
              #default="validationContext"
              name="Gói khám áp dụng"
              rules="required"
            >
              <select-pricing
                v-model="itemLocal.pricings"
                required
                :default-value="itemLocal.pricings"
                label="Gói khám áp dụng"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <b-form-group label="Khách hàng áp dụng:">
              <div class="demo-inline-spacing mt-0 pt-0">

                <b-form-radio
                  v-model="itemLocal.is_apply_all"
                  name="is_apply_all"
                  :value="true"
                  class="custom-control-primary"
                >
                  Áp dụng cho tất cả
                </b-form-radio>

                <b-form-radio
                  v-model="itemLocal.is_apply_all"
                  name="is_apply_all"
                  :value="false"
                  class="custom-control-primary"
                >
                  Chọn đối tượng
                </b-form-radio>
              </div>
            </b-form-group>

          </b-col>

          <b-col
            v-if="!itemLocal.is_apply_all"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Nhóm khách hàng"
              rules="required"
            >
              <select-customer-group
                v-model="itemLocal.customer_groups"
                label="Nhóm khách hàng"
                :default-value="itemLocal.customer_groups"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col
            v-if="!itemLocal.is_apply_all"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Khách hàng áp dụng"
              rules="required"
            >
              <select-patient
                v-model="itemLocal.customers"
                label="Khách hàng áp dụng"
                :default-value="itemLocal.customers"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="validationContext"
              name="Số lượt sử dụng"
              rules="required"
            >
              <number-input
                v-model="itemLocal.number_of_uses"
                label="Số lần khám"
                :default-value="itemLocal.number_of_uses"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Hạn sử dụng từ ngày"
              rules="required"
            >
              <select-date
                v-model="itemLocal.start_time"
                required
                :default-value="itemLocal.start_time"
                label="Hạn sử dụng từ ngày"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Hạn sử dụng đến ngày"
              rules="required"
            >
              <select-date
                v-model="itemLocal.end_time"
                required
                :default-value="itemLocal.end_time"
                label="Hạn sử dụng đến ngày"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            class="mt-2"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.status"
                name="check-button"
                switch
                inline
              >
                Kích hoạt?
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- submit -->
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
  VBTooltip,
  BFormRadio,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, minValue } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { togglePasswordConfirmVisibility } from '@/layouts/mixins/forms';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import SelectPricing from '@/views/components/SelectPricing.vue';
import SelectCustomerGroup from '@/views/components/SelectCustomerGroup.vue';
import SelectPatient from '@/views/components/SelectPatient.vue';
import useVoucherModal from './useVoucherModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    NumberInput,
    BFormRadio,
    SelectDate,
    SelectPricing,
    SelectCustomerGroup,
    SelectPatient,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmVisibility],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      minValue,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useVoucherModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
